import React, {Fragment} from 'react';
import Search from './Fields/Search';
import Range from './Fields/Range';
import MultiSelect from './Fields/MultiSelect';
import Toggle from './Fields/Toggle';
import MultiSelectWithSearch from './Fields/MultiSelectWithSearch';

const AllFilters = ({group, filters, dispatch, handleUpdateMultiSelect, multiScreen, hasMultiselect}) => {
  if (!filters.length) return null;
  return filters
    ?.filter((filt) => filt?.filterable?.type !== 'search')
    .map((filter) => (
      <Fragment key={filter?.key ?? filter?.type}>
        {/* {filter &&
      filter?.filterable?.type === 'search' &&
      filter?.type === 'string' ? (
        <Search
          filter={filter}
          group={group}
          dispatch={dispatch}
          multiScreen={multiScreen}
        />
      ) : null} */}
        {filter && filter?.filterable?.type === 'range' && !multiScreen ? (
          <Range filter={filter} group={group} dispatch={dispatch} />
        ) : null}
        {filter && filter?.type === 'array' ? (
          <MultiSelect
            filter={filter}
            group={group}
            handleUpdateMultiSelect={handleUpdateMultiSelect}
            dispatch={dispatch}
            label={filter?.title}
            multiScreen={multiScreen}
          />
        ) : null}

        {filter && filter?.type === 'string' && filter?.filterable?.type === 'multiselect' && !hasMultiselect ? (
          <MultiSelectWithSearch
            filter={filter}
            group={group}
            handleUpdateMultiSelect={handleUpdateMultiSelect}
            dispatch={dispatch}
            label={filter?.title}
            multiScreen={multiScreen}
          />
        ) : null}

        {filter && filter?.filterable?.type === 'toggle' ? (
          <Toggle dispatch={dispatch} group={group} filter={filter} />
        ) : null}
      </Fragment>
    ));
};

export default AllFilters;
