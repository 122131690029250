import React, {useState, useEffect} from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import ScannerTable from './ScannerTable';
import Header from '../Header/Header';
import 'react-datepicker/dist/react-datepicker.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scanner = ({
  group,
  groupNumber,
  multiScreen,
  dispatch,
  hasChild,
  openOptionsModal,
  showReportModal,
  openReportModal,
  closeReportModal,
  hasReportPopout,
  optionsColumns,
  optionsAllowed,
  getSymbolData,
  wsSocket,
  openLockedModal,
  groupCollection,
  scannerLayout,
  groups,
  hideSearch,
  hideHeader,
  allowExportCSV,
  useSecondaryHeaderTitle,
  hidePagination,
  openModal,
  hideDateSelectorForGroups,
  alignColumns,
  trimNumbersFromSymbol = false,
  hasMainReport,
  reports,
  updatingDate,
  setUpdatingDate,
  currentTab,
  setCurrentTab,
  currentDate,
  secondaryHeader,
  tabTitle,
  useTabTitle,
  showLegendModal,
  openLegendModal,
  closeLegendModal,
  displayLegend,
  displayColorLegend,
  displayEarningsLegend
}) => {
  const [symbolsWithReports, setSymbolsWithReports] = useState([]);
  const [reportForGroup, setReportForGroup] = useState(null);

  useEffect(() => {
    if (!Object.keys(reports ?? {})?.length || !group?.report?.symbolReport) {
      return;
    }
    const matchingReport = reports?.[group?.report?.symbolReport] ?? null;
    if (!matchingReport) {
      return;
    }
    const {rawData} = matchingReport;
    if (!rawData?.length) {
      return;
    }
    const symbolsOnly = rawData.map((r) => r?.Symbol?.value ?? null).filter((s) => s);
    setSymbolsWithReports(symbolsOnly);
    setReportForGroup(matchingReport);
  }, [reports, group]);

  const handleChangePage = (event, newPage) => {
    if (!group?.group) return;
    dispatch({type: 'SET_PAGE', payload: {group, page: newPage}});
  };

  /**
   * Check if it is currently in trading hours
   * @returns Boolean
   */
  // const isTradingHours = () => {
  //   const date = dayjs().tz('America/New_York');
  //   const startOfDay = dayjs().tz('America/New_York').startOf('date');

  //   const dayOfWeek = date.format('d');

  //   const difference = date.diff(startOfDay) / 1000;

  //   if (dayOfWeek >= 1 && dayOfWeek <= 5 && difference >= 34200 && difference <= 57600) {
  //     return true;
  //   }

  //   return false;
  // };

  const displaySecondaryTitle = () => {
    if (!group?.group?.includes('MP_DOT')) return false;
    // if (!group?.group?.includes('MP_DOT') && !group?.group?.includes('ShadowSignalScanner')) return false;
    const groupTitle = group?.group?.includes('+') ? group?.group?.split('+') : group?.group?.split('_');
    if (!groupTitle?.length || groupTitle?.length <= 1) {
      return '';
    }
    if (!groupTitle[1]) {
      return '';
    }
    if (groupTitle[1] === 'Main' || groupTitle[1] === 'Secondary') {
      return '';
    }
    return groupTitle[1];
  };

  return (
    <div className={`${hasChild ? 'hasChild pb-7' : ''} scanner-wrap`}>
      <Header
        name={group?.groupTitle  ?? ''}
        useTabTitle={useTabTitle}
        tabTitle={tabTitle}
        dispatch={dispatch}
        filters={group?.searchValue?.filters}
        group={group}
        page={group?.page}
        handleChangePage={handleChangePage}
        rowsPerPage={group?.rowsPerPage}
        count={Object.keys(group?.filteredData || {})?.length ?? 0}
        wsSocket={wsSocket}
        groupCollection={groupCollection}
        scannerLayout={scannerLayout}
        groups={groups}
        hideHeader={hideHeader}
        hideDateSelectorForGroups={hideDateSelectorForGroups}
        hideSearch={hideSearch}
        hidePagination={hidePagination}
        secondaryHeader={displaySecondaryTitle()}
        useSecondaryHeaderTitle={useSecondaryHeaderTitle}
        groupNumber={groupNumber}
        openReportModal={openReportModal}
        hasMainReport={hasMainReport}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        updatingDate={updatingDate}
        setUpdatingDate={setUpdatingDate}
        currentDate={currentDate}
        showLegendModal={showLegendModal}
        openLegendModal={openLegendModal}
        displayLegend={displayLegend}
        displayColorLegend={displayColorLegend}
        displayEarningsLegend={displayEarningsLegend}
      />
      {group ? (
        <div className="scanner">
          <div className="flex justify-between mx-auto w-full delay-info">
            {!process.env?.REACT_APP_LIVE_DATA ? (
                <p className="delay-info w-full ">
                  <small>15 Minute Delay</small>
                </p>
              ) : null}
            {/* {!isTradingHours() ? (
              <p className="after-market-hours w-full text-right">
                <small>Data represents values from prior business day&apos;s close</small>
              </p>
            ) : null} */}
          </div>

          <ScannerTable
            tableData={group?.filteredData ?? {}}
            dataTypes={group?.dataTypes ?? []}
            orderBy={group?.orderBy}
            group={group}
            page={group?.page}
            multiScreen={multiScreen}
            order={group?.order}
            rowsPerPage={group?.rowsPerPage}
            dispatch={dispatch}
            handleChangePage={handleChangePage}
            getSymbolData={getSymbolData}
            openModal={openModal}
            openOptionsModal={openOptionsModal}
            showReportModal={showReportModal}
            openReportModal={openReportModal}
            optionsColumns={optionsColumns}
            optionsAllowed={optionsAllowed}
            hasReportPopout={hasReportPopout}
            openLockedModal={openLockedModal}
            alignColumns={alignColumns}
            allowExportCSV={allowExportCSV}
            trimNumbersFromSymbol={trimNumbersFromSymbol}
            reports={reports}
            symbolsWithReports={symbolsWithReports}
          />
        </div>
      ) : (
        <div className="scanner"> Loading Scanner... </div>
      )}
    </div>
  );
};

export default React.memo(Scanner, (prev, next) => {
  const dataSame = JSON.stringify(prev?.group?.filteredData) === JSON.stringify(next?.group?.filteredData);
  const reportsSame = JSON.stringify(prev?.reportsSame) === JSON.stringify(next?.group?.filteredData);
  const orderBySame = prev?.group?.orderBy === next?.group?.orderBy;
  const orderSame = prev?.group?.order === next?.group?.order;
  const pageSame = prev?.group?.page === next?.group?.page;
  const rowsPerPageSame = prev?.group?.rowsPerPage === next?.group?.rowsPerPage;
  const trimNumbersFromSymbolSame = prev?.trimNumbersFromSymbol === next?.trimNumbersFromSymbol;
  const updatingDate = prev.updatingDate === next.updatingDate;
  const currentTabSame = prev.currentTab === next.currentTab;

  return dataSame && orderBySame && orderSame && pageSame && rowsPerPageSame && trimNumbersFromSymbolSame && updatingDate && currentTabSame && reportsSame;
});
