const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      //   maxHeight: '60%',
      padding: '30px 20px',
      height: '85%',
      width: '90%',
    },
  };
  
  const customStylesOptions = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      //   maxHeight: '60%',
      padding: '20px',
      height: 'auto',
      width: '50%',
    },
  };
  
  const customStylesLocked = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      //   maxHeight: '60%',
      padding: '20px',
      height: 'auto',
      width: '50%',
    },
  };
  
  const customStylesUpcoming = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      //   maxHeight: '60%',
      padding: '20px',
      height: 'auto',
      width: '50%',
    },
  };
  
  const customStylesReports = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      //   maxHeight: '60%',
      padding: '20px',
      height: 'auto',
      width: '50%',
    },
  };

  const customStylesLegend = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      //   maxHeight: '60%',
      padding: '20px',
      height: 'auto',
      width: '50%',
    },
  };

  
  export { customStyles, customStylesOptions, customStylesLocked, customStylesUpcoming, customStylesReports, customStylesLegend };